import { motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Section as StyledSection,
  SectionButton,
  SectionImage,
  SectionInfoContent,
  SectionSubtitle,
  SectionTitle
} from '../styles'

const sectionListVariants = {
  onscreen: {
    transition: { staggerChildren: 0.5  },
  },
}

const Section = React.forwardRef((props, ref) => {
  const {
    image,
    title,
    subtitle,
    btnHoverColor,
    btnBgColor,
    handleContactNavigate,
    even = false,
  } = props

  const { t } = useTranslation()

  const descriptionVariants = {
    offscreen: {
      x: even ? -100 : 100,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        bounce: 0.3,
        duration: 0.8,
      },
    },
  }

  const imageVariants = {
    offscreen: {
      y: even ? -100 : 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        bounce: 0.3,
        duration: 0.8,
      },
    },
  }

  return (
    <StyledSection
      ref={ref}
      as={motion.section}
      initial='offscreen'
      whileInView='onscreen'
      variants={sectionListVariants}
      viewport={{ once: true, amount: 0.5 }}>
      <SectionImage src={image} as={motion.img} variants={imageVariants} />

      <SectionInfoContent as={motion.div} variants={descriptionVariants}>
        <SectionTitle>{title}</SectionTitle>
        <SectionSubtitle>{subtitle}</SectionSubtitle>
        <SectionButton
          onClick={handleContactNavigate}
          hbgc={btnHoverColor}
          bgc={btnBgColor}>
          {t('btn.section')}
        </SectionButton>
      </SectionInfoContent>
    </StyledSection>
  )
})

export default Section
