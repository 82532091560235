import { faPhoneFlip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useScreenWidth from '../../hooks/useScreenWidth'
import {
  NavigationCall,
  NavigationItem,
  NavigationList,
} from '../../styles'
import AnimatedListItem from './AnimatedListItem'

const subNavListItemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const AnimatedList = (props) => {
  const {
    toggleMenu,
    handleConsultingNavigate,
    handleInvestmentNavigate,
    handleOrganicsNavigate,
    handleContactNavigate,
  } = props
  const screenWidth = useScreenWidth()
  const { t } = useTranslation()
  const handleCall = () => window.open('tel:00905541762407')

  const items = [
    {
      label: t('consulting.label'),
      navigate: handleConsultingNavigate,
    },
    {
      label: t('investment.label'),
      navigate: handleInvestmentNavigate,
    },
    {
      label: t('organics.label'),
      navigate: handleOrganicsNavigate,
    },
    {
      label: t('contact.label'),
      navigate: handleContactNavigate,
    },
  ]

  const mainNavListItemVariants = screenWidth <= 900 && {
    open: {
      display: 'flex',
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      display: 'none',
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  }

  return (
    <NavigationList
      as={motion.nav}
      animate={toggleMenu || screenWidth > 900 ? 'open' : 'closed'}
      variants={mainNavListItemVariants}>
      {items.map((item) => {
        return (
          <AnimatedListItem key={item.label} handleNavigate={item.navigate} label={item.label} />
        )
      })}

      <hr
        style={{
          height: screenWidth >= 900 ? '3rem' : '0',
          width: screenWidth >= 900 ? '0' : '70%',
          rotate: screenWidth >= 900 ? 90 : 0,
        }}
        as={motion.hr}
        variants={subNavListItemVariants}
      />

      <NavigationCall
        as={motion.div}
        variants={subNavListItemVariants}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleCall}>
        <FontAwesomeIcon icon={faPhoneFlip} />
        <NavigationItem>+90 554 176 2407</NavigationItem>
      </NavigationCall>
    </NavigationList>
  )
}

export default AnimatedList
