import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import './App.css'
import Calendar from './assets/calendar2.png'
import Section from './components/Section'
import Consulting from './assets/consulting.jpg'
import FirstSectionImage from './assets/firstSectionImage.svg'
import Footer from './footer/Footer'
import Header from './header'
import Meeting from './assets/meeting.svg'
import Payment from './assets/payment2.png'
import OrganicsSvg from './assets/peageorganic.svg'
import {
  ContainerSection,
  ContentDesription,
  CoverContent,
  CoverDescriptionSubtitle,
  CoverDescriptionTitle,
  CoverSection,
  CoverSectionSeperator,
  Overlay,
  StyledCoverSeperatorIcon,
  WhyMeDescription,
  WhyMeDivider,
  WhyMeSection,
  WhyMeSectionContainer,
  WhyMeTitle,
} from './styles'
import Trust from './assets/trust6.png'

const whyMeListVariants = {
  onscreen: {
    transition: { staggerChildren: 0.5 },
  },
}

const xVariants = {
  offscreen: {
    x: -100,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
}

const yVariants = {
  offscreen: {
    y: -100,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
}

const plusXVariants = {
  offscreen: {
    x: 100,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
}

function App() {
  const consultingRef = useRef()
  const investmentRef = useRef()
  const organicsRef = useRef()
  const contactRef = useRef()

  const { t } = useTranslation()

  const handleConsultingNavigate = () => {
    consultingRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }

  const handleInvestmentNavigate = () => {
    investmentRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }

  const handleOrganicsNavigate = () => {
    organicsRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }

  const handleContactNavigate = () => {
    contactRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }

  return (
    <>
      <Header
        handleConsultingNavigate={handleConsultingNavigate}
        handleInvestmentNavigate={handleInvestmentNavigate}
        handleOrganicsNavigate={handleOrganicsNavigate}
        handleContactNavigate={handleContactNavigate}
      />

      <CoverSection bg={Consulting}>
        <Overlay />
        <CoverContent>
          <ContentDesription bg={Consulting}>
            <CoverDescriptionTitle>Mehmet Bakir</CoverDescriptionTitle>
            <CoverDescriptionSubtitle>
              {t('cover.subtitle')}
            </CoverDescriptionSubtitle>
          </ContentDesription>
        </CoverContent>
      </CoverSection>

      <CoverSectionSeperator>
        <StyledCoverSeperatorIcon
          onClick={handleConsultingNavigate}
          icon={faArrowDown}
        />
      </CoverSectionSeperator>

      <ContainerSection>
        <Section
          ref={consultingRef}
          image={Meeting}
          title={t('consulting.label')}
          subtitle={t('section.consulting.description')}
          btnHoverColor='#84b9b1'
          btnBgColor='#A3E4DB'
          handleContactNavigate={handleContactNavigate}
        />
        <Section
          ref={investmentRef}
          image={FirstSectionImage}
          title={t('investment.label')}
          subtitle={t('section.investment.description')}
          btnBgColor='#FFF8F3'
          handleContactNavigate={handleContactNavigate}
          even={true}
        />

        <Section
          ref={organicsRef}
          image={OrganicsSvg}
          title={t('organics.label')}
          subtitle={t('section.organics.description')}
          btnHoverColor='#84b9b1'
          btnBgColor='#A3E4DB'
          handleContactNavigate={handleContactNavigate}
        />
      </ContainerSection>

      <WhyMeSectionContainer
        as={motion.div}
        initial='offscreen'
        whileInView='onscreen'
        variants={whyMeListVariants}
        viewport={{ once: true, amount: 0.5 }}>
        <WhyMeSection
          as={motion.section}
          variants={xVariants}
          whileHover={{ scale: 1.1 }}>
          <img src={Trust} alt='trust' />
          <WhyMeTitle>{t('whyme.trust.title')}</WhyMeTitle>
          <WhyMeDivider />
          <WhyMeDescription>{t('whyme.trust.description')}</WhyMeDescription>
        </WhyMeSection>

        <WhyMeSection
          as={motion.section}
          variants={yVariants}
          whileHover={{ scale: 1.1 }}>
          <img src={Payment} alt='payment' />
          <WhyMeTitle>{t('whyme.payment.title')}</WhyMeTitle>
          <WhyMeDivider />
          <WhyMeDescription>{t('whyme.payment.description')}</WhyMeDescription>
        </WhyMeSection>

        <WhyMeSection
          as={motion.section}
          variants={plusXVariants}
          whileHover={{ scale: 1.1 }}>
          <img src={Calendar} alt='calendar' />
          <WhyMeTitle>{t('whyme.time.title')}</WhyMeTitle>
          <WhyMeDivider />
          <WhyMeDescription>{t('whyme.time.description')}</WhyMeDescription>
        </WhyMeSection>
      </WhyMeSectionContainer>
      <Footer ref={contactRef} />
    </>
  )
}

export default App
