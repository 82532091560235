import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import smoothscroll from 'smoothscroll-polyfill'
import App from './App'
import './i18n'
import './index.css'

smoothscroll.polyfill()

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={'loading..'}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)
