/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { useTranslation } from 'react-i18next'
import useScreenWidth from '../hooks/useScreenWidth'
import MBakir from '../assets/mbakir.jpg'
import {
  ContainerFooter,
  Copyright,
  CopyrightLink,
  CopyrightText,
  FindMeContent,
  FindMeTitle,
  FooterAdress,
  FooterContent,
  FooterEmail,
  FooterImage,
  FooterInfoContainer,
  FooterTelephone,
  FooterTitle,
  SocialMediaIcons,
} from '../styles'

const Footer = React.forwardRef((props, ref) => {
  const screenWidth = useScreenWidth()
  const { t } = useTranslation()

  return (
    <ContainerFooter ref={ref}>
      <FooterContent>
        <FooterImage src={MBakir} alt='Mbakir' />
        <FooterInfoContainer>
          <FooterTitle>Mehmet Bakir</FooterTitle>
          <FooterAdress>Nazilli, Aydin - Turkey</FooterAdress>
          <FooterTelephone>+90 554 176 2407</FooterTelephone>
          <FooterEmail>bakmeh.mb@gmail.com</FooterEmail>
        </FooterInfoContainer>

        <FindMeContent>
          <FindMeTitle>{t('footer.find')}</FindMeTitle>
          <SocialMediaIcons>
            <a
              style={{
                width: screenWidth > 570 ? '50px' : '25px',
                padding: screenWidth > 570 ? '15px' : '7.5px',
                fontSize: screenWidth > 570 ? '25px' : '12.5px',
                textAlign: 'center',
                textDecoration: 'none',
                background: '#3B5998',
                color: 'white',
              }}
              href='https://www.facebook.com/profile.php?id=100043774576228'
              target='_blank'
              className='fa fa-facebook'
              alt='facebook'
              rel='noreferrer'></a>
            <a
              style={{
                width: screenWidth > 570 ? '50px' : '25px',
                padding: screenWidth > 570 ? '15px' : '7.5px',
                fontSize: screenWidth > 570 ? '25px' : '12.5px',
                textAlign: 'center',
                textDecoration: 'none',
                background: '#007bb5',
                color: 'white',
              }}
              href='https://www.linkedin.com/in/mehmet-bakir-553949ba/'
              target='_blank'
              className='fa fa-linkedin'
              alt='linkedin'
              rel='noreferrer'></a>
          </SocialMediaIcons>
        </FindMeContent>
      </FooterContent>
      <Copyright>
        <CopyrightText>
          &copy; Copyright {new Date().getFullYear()} Mehmet Bakir
          All Rights Reserved.
        </CopyrightText>
        <CopyrightText>
          Website is made by Ibrahim Delice (ibrahimdelice96@gmail.com).
        </CopyrightText>
        <CopyrightText>
          <CopyrightLink href='https://storyset.com/people' target='_blank'>
            @People illustrations by Storyset
          </CopyrightLink>{' '}
          <CopyrightLink href='http://jeshoots.com' target='_blank'>
            @Jeshoots
          </CopyrightLink>
        </CopyrightText>
      </Copyright>
    </ContainerFooter>
  )
})

export default Footer
