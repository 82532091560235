import { motion } from 'framer-motion'
import React from 'react'
import { NavigationItem } from '../../styles'

const listItemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const AnimatedListItem = (props) => {
  const { handleNavigate, label } = props

  return (
    <NavigationItem
      as={motion.span}
      variants={listItemVariants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={handleNavigate}>
      {label}
    </NavigationItem>
  )
}

export default AnimatedListItem
