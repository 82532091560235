import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useScreenWidth from '../hooks/useScreenWidth'
import {
  DisplayLanguage,
  Dropdown,
  HeaderContainer,
  LanguageItem,
  LanguageList,
  NavigationOverlay,
  Header as UIHeader,
} from '../styles'
import AnimatedList from './components/AnimatedList'
import MainLogo from '../assets/BB.png'
import { MenuButton } from './components/MenuButton'

const listItemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const listVariants = {
  open: {
    display: 'block',
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    display: 'none',
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const ListItem = ({ value, label, handleLocaleChange }) => {
  return (
    <LanguageItem
      as={motion.button}
      variants={listItemVariants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => handleLocaleChange(value)}
      value={value}>
      {label}
    </LanguageItem>
  )
}

const Header = ({
  handleConsultingNavigate,
  handleInvestmentNavigate,
  handleOrganicsNavigate,
  handleContactNavigate,
}) => {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [showLanguages, setShowLanguages] = useState(false)
  const { i18n } = useTranslation()
  const screenWidth = useScreenWidth()

  const toggleNav = () => setToggleMenu((prev) => !prev)

  const handleLocaleVisibility = () => {
    setShowLanguages((prev) => !prev)
  }

  const handleLocaleChange = (locale) => {
    i18n.changeLanguage(locale, (err, t) => {
      if (err) return console.log('something went wrong loading', err)
      console.error(err)
    })
  }

  const currentLanguageLabel = () => {
    switch (i18n.language) {
      case 'en':
      case 'English':
        return 'English'
      case 'dk':
      case 'Dansk':
        return 'Dansk'
      case 'tr':
      case 'Türkçe':
        return 'Türkçe'
      default:
        return 'Unknown'
    }
  }

  return (
    <UIHeader>
      {screenWidth <= 900 && (
        <MenuButton
          isOpen={toggleMenu}
          onClick={toggleNav}
          strokeWidth='3'
          color='#3399cc'
          transition={{ ease: 'easeOut', duration: 0.2 }}
          width='26'
          height='24'
        />
      )}
      <img
        style={{
          height: 'auto',
          maxWidth: '200px',
          marginRight: screenWidth <= 900 ? 'auto' : 'unset',
          marginLeft: screenWidth <= 900 ? '1rem' : 'unset',
        }}
        src={MainLogo}
        alt='Bakir consultancy logo'
      />
      <HeaderContainer
        as={motion.div}
        animate={toggleMenu ? 'open' : 'closed'}
        initial={toggleMenu}>
        <AnimatedList
          toggleMenu={toggleMenu}
          handleConsultingNavigate={handleConsultingNavigate}
          handleInvestmentNavigate={handleInvestmentNavigate}
          handleOrganicsNavigate={handleOrganicsNavigate}
          handleContactNavigate={handleContactNavigate}
        />

        <Dropdown
          as={motion.button}
          value={i18n.languages[0]}
          onClick={handleLocaleVisibility}
          onMouseLeave={() => setShowLanguages(false)}>
          <FontAwesomeIcon icon={faGlobe} />
          <DisplayLanguage>{currentLanguageLabel()}</DisplayLanguage>

          <LanguageList
            as={motion.div}
            animate={showLanguages ? 'open' : 'closed'}
            variants={listVariants}>
            <ListItem
              value='en'
              label='English'
              handleLocaleChange={handleLocaleChange}
            />
            <ListItem
              value='dk'
              label='Dansk'
              handleLocaleChange={handleLocaleChange}
            />
            <ListItem
              value='tr'
              label='Türkçe'
              handleLocaleChange={handleLocaleChange}
            />
          </LanguageList>
        </Dropdown>
      </HeaderContainer>

      {(toggleMenu || screenWidth <= 900) && (
        <NavigationOverlay onClick={() => setToggleMenu(false)} />
      )}
    </UIHeader>
  )
}

export default Header
