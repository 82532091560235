import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css, keyframes } from 'styled-components'

const Typography = css`
  font-family: 'Poppins', sans-serif;
`

const RemainingHeightAfterNavAndCover = css`
  height: calc(100vh - 70vh - 70px);
`

export const ContainerSection = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  & > section:nth-child(even) {
    flex-direction: row-reverse;
  }

  @media (max-width: 570px) {
    & > section:nth-child(even) {
      flex-direction: column;
    }
  }
`

export const Section = styled.section`
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20%;
  background-color: #a3e4db;
  gap: 5em;

  transition: padding 0.4s ease-in-out;

  &:nth-child(odd) {
    background: #fff8f3;
  }

  @media (max-width: 1000px) {
    padding: 10%;
    gap: 2.5em;
  }

  @media (max-width: 750px) {
    padding: 0;
  }

  @media (max-width: 570px) {
    height: 100%;
    flex-direction: column;
    padding: 0;
    gap: 0;
  }

  @media (min-width: 2100px) {
    gap: 10em;
  }
`

export const SectionInfoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5em;
  width: 100%;
  border-width: 2px;
  padding: 0 15px;
  height: auto;
  border-radius: 5px;
  border-color: #f3e9dd;
  flex-direction: column;

  @media (max-width: 570px) {
    align-items: center;
  }
`

export const SectionImage = styled.img`
  height: clamp(300px, 25vw, 100%);
  width: auto;

  transition: width 0.5s ease-in-out;
`

export const SectionTitle = styled.h1`
  ${Typography}

  position: relative;
  font-size: clamp(1rem, 1.3vw, 1.5rem);

  &::after {
    position: absolute;
    content: '';
    min-width: 2em;
    height: 3.5px;
    bottom: -0.35em;
    left: 0;
    color: black;
    background-color: black;
  }
`

export const SectionSubtitle = styled.p`
  ${Typography}

  font-size: clamp(0.8125rem, .9vw, 1.1rem);
`

export const SectionButton = styled.a`
  ${Typography}

  background-color: ${(props) => props.bgc};
  border-radius: 50px;
  color: ${(props) => props.color};
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: clamp(0.8rem, 0.9vw, 1.5rem);
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    background-color: ${(props) => props.hbgc};
  }

  @media (max-width: 570px) {
    padding: 5px 17px;
    margin-bottom: 15px;
  }
`

export const Header = styled.header`
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  height: 5em;
  padding: 0 1rem;
  align-items: center;
`

export const HeaderContainer = styled.div`
  @media (min-width: 900px) {
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2em;
  max-width: 70vw;
  margin-right: auto;
  margin-left: auto;
  }
`

export const NavigationList = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2em;
  height: 100%;

  @media (max-width: 950px) {
    gap: 1em;
  }

  @media (max-width: 900px) {
    position: absolute;
    top: 5em;
    left: 0;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 70vh;
    background-color: white;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    z-index: 1;
  }
`

export const NavigationButton = styled.button`
  background-color: transparent;
  border: none;
  display: none;
  color: #000;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 570px) {
    display: block;
  }
`

const navigationItemKeyframes = keyframes`
  0% { width: 0 }
  100% { width: 100% }
`

export const NavigationItem = styled.span`
  ${Typography}

  position: relative;
  font-size: clamp(0.8125rem, 0.9vw, 1.1rem);
  cursor: pointer;
  font-weight: 600;

  &:hover {
    color: #697a95;
  }

  &:hover:after {
    content: '';
    position: absolute;
    border-bottom: 2px solid black;
    height: 1px;
    bottom: -5px;
    left: 0;
    width: 100%;
    animation-name: ${navigationItemKeyframes};
    animation-duration: 0.35s;
  }

  @media (max-width: 570px) {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.555);
    text-align: center;
    /* margin-right: 0px; */
    padding: 20px 0;

    &:not(:last-child) {
      max-width: fit-content;
    }

    &:hover:after {
      content: '';
      bottom: 5px;
    }
  }
`

export const NavigationCall = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
  height: fit-content;
`
export const ContainerFooter = styled.footer`
  width: 100%;
  height: 100%;
  background-color: #c9def0;
  padding-top: 2em;

  @media (max-width: 900px) {
    padding: 12.5px;
  }
`

export const FooterContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  gap: 50px;

  @media (max-width: 570px) {
    width: 100%;
    gap: 25px;
  }
`

export const FooterTitle = styled.h4`
  ${Typography}

  font-size: clamp(0.8125rem, .9vw, 1.1rem);
  color: black;
`

export const FooterImage = styled.img`
  width: 150px;
  height: 200px;

  @media (max-width: 570px) {
    width: 75px;
    height: 100px;
  }
`

export const FooterAdress = styled.p`
  ${Typography}
  font-size: clamp(0.6rem, .75vw, 1rem);
  color: black;
`

export const FooterInfoContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
`

export const FooterTelephone = styled.p`
  ${Typography}

  color: black;
  font-size: clamp(0.6rem, 0.75vw, 1rem);
`

export const FooterEmail = styled.p`
  ${Typography}

  color: black;
  font-size: clamp(0.6rem, 0.75vw, 1rem);
`

export const FindMeContent = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
`

export const FindMeTitle = styled.h4`
  ${Typography}

  font-size: clamp(0.8125rem, .9vw, 1.1rem);
  color: black;
`

export const SocialMediaIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 15px;

  @media (max-width: 570px) {
    gap: 7.5px;
    justify-content: flex-start;
  }
`

export const Divider = styled.hr`
  /* border-left: 2px solid black; */
  /* height: 80%; */
  width: 80%;

  @media (max-width: 900px) {
    /* border-left: none;
    border-bottom: 2px solid black; */
    width: 80%;
    height: 0;
  }
`

export const CoverContainer = styled.div`
  background-color: transparent;
  opacity: 0.59;
  height: 100%;
`

export const CoverWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const CoverSection = styled.section`
  width: 100%;
  height: 70vh;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media (max-width: 1024px) {
    background-position: center center;
  }

  @media (max-width: 767px) {
    background-position: 47% 0px;
  }
`

export const CoverSectionSeperator = styled.div`
  ${RemainingHeightAfterNavAndCover}
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledCoverSeperatorIcon = styled(FontAwesomeIcon)`
  color: #c9def0;
  font-size: 48px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    color: #697a95;
    transform: translateY(10px);
  }
`

export const Overlay = styled.div`
  background-color: transparent;
  background-image: linear-gradient(150deg, #748cc7cf 0%, #6cabd466 100%);
  width: 100%;
  height: 100%;
  opacity: 1;
`

export const NavigationOverlay = styled.div`
  ${RemainingHeightAfterNavAndCover}

  width: 100%;
  position: absolute;
  bottom: 0;
`
export const CoverContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export const ContentWidth = styled.div`
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
  transition: padding 0.5s ease-in-out;
  height: 100%;

  @media (max-width: 570px) {
    padding: 0;
    width: 100%;
    margin: 0;
  }
`

const coverDescriptionKeyframes = keyframes`
  0% { opacity: 0 }
  100% { width: 1 }
`

export const ContentDesription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 1em;
  text-align: center;
  animation: ${coverDescriptionKeyframes};
  animation-duration: 3s;
`

export const CoverDescriptionTitle = styled.span`
  ${Typography}

  text-transform: uppercase;
  letter-spacing: 5px;
  position: relative;
  font-size: clamp(1.1rem, 2vw, 3rem);
  max-width: fit-content;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    color: black;
    background-color: black;
  }
`

export const CoverDescriptionSubtitle = styled.p`
  ${Typography}

  font-size: clamp(1.25rem, 2.5vw, 3rem);
  font-weight: 800;
`

export const Dropdown = styled.button`
  min-width: 6em;
  min-height: 2.71428571em;
  max-height: 100%;
  outline: none;
  padding: 0.35em 0.7em;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  background-color: transparent;
  position: relative;

  &:hover {
    background-color: #c9def0;
  }
`

export const LanguageList = styled.div`
  position: absolute;
  top: 2.71428571em;
  min-width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  background-color: white;
  border-radius: 0 0 5px 5px;
  border-top: none;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  background-color: white;
`

export const LanguageItem = styled.button`
  ${Typography}

  background-color: transparent;
  border: none;
  font-size: 0.8rem;
  font-weight: 800;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0.5rem 0;

  &:hover {
    background-color: #c9def0;
  }
`

export const DisplayLanguage = styled.button`
  ${Typography}

  background-color: transparent;
  border: none;
  font-size: 0.8rem;
  font-weight: 800;
  width: 100%;
  cursor: pointer;
`

export const StyledMenuToggle = styled.button`
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  border-radius: 50%;
  background: transparent;
  margin-left: 1rem;
`

export const WhyMeSectionContainer = styled.div`
  height: 60vh;
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3, 25vw);
  justify-content: center;
  align-items: center;
  padding: 0 2em;
  gap: 6em;

  @media (max-width: 1420px) {
    gap: 2em;
  }

  @media (max-width: 1050px) {
    padding: 2em 2em;
    height: 100%;
    grid-template-columns: repeat(2, 1fr);
    & > section:nth-child(3) {
      grid-column: 1 / 3;
    }
  }

  @media (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);

    & > section:nth-child(3) {
      grid-column: 1;
    }
  }
`

export const WhyMeSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
  max-height: 100%;
  flex-direction: column;
  border: 1px solid rgb(163, 228, 219);
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  gap: 1.5em;

  &:hover {
    cursor: pointer;
    background-color: #c9def0;
  }

  @media (max-width: 1050px) {
    height: 100%;
    padding: 2em 0;
  }
`
export const WhyMeTitle = styled.p`
  ${Typography}
  font-size: clamp(1rem, 1.3vw, 1.5rem);
  font-weight: 800;
`

export const WhyMeDescription = styled.div`
  ${Typography}
  font-size: clamp(0.8125rem, .9vw, 1.1rem);
  text-align: center;
  min-width: 80%;
  padding: 0 1.5em;
`

export const WhyMeDivider = styled.div`
  border-bottom: 2px solid black;
  height: 2px;
  width: 50%;
  margin-top: -1em;
`

export const Copyright = styled.p`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-direction: column;
`

export const CopyrightText = styled.small`
  ${Typography}
  font-size: clamp(0.6rem, .7vw, .9rem);
`
export const CopyrightLink = styled.a`
  text-decoration: none;
  font-size: clamp(0.4rem, 0.5vw, 0.7rem);
`
